<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE DATA RECEIVE OF ITEMS</title>
    <section class="content-header">
      <h1>
        Update Data Receive of Items
        <br />
        <h4>Please enter the transaction data for the item receive list</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Items Receive List Transaction</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Data Receive of Items</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Receive Number </label>
                  <input
                    type="hidden"
                    readonly
                    v-model="idxpenh"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    readonly
                    v-model="receiptno"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">BL Number / Travel Document </label>
                  <input
                    type="text"
                    v-model="blnumber"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier</label>
                  <select2
                    :data="suplist"
                    :value="valuesup"
                    @update="updatesup($event)"
                  ></select2>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="notespenh"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Receive Date </label>
                  <input
                    type="date"
                    v-model="tglreceipt"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/penerimaan">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Data Receive of Items</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Customs Document Code</label>
                  <input
                    type="hidden"
                    readonly
                    v-model="idxtpbheader"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    readonly
                    v-model="kodedokumenpabean"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Register Number </label>
                  <input
                    type="text"
                    readonly
                    v-model="nomordaftar"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Register Date </label>
                  <input
                    type="text"
                    readonly
                    v-model="tgldaftar"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      idxtpbheader: "",
      kodedokumenpabean: "",
      nomordaftar: "",
      tgldaftar: "",
      idxpenh: "",
      receiptno: "",
      notespenh: "",
      tglreceipt: "",
      blnumber: "",
      suplist: [],
      valuesup: "",
    };
  },
  created() {
    //this.loadData();
    this.getSupplierList();
    this.idxpenh = this.$route.params.id;
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/penerimaan_h/getpenerimaan_hbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl +
        "penerimaan_h/getpenerimaan_hbyid?id=" +
        this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          this.idxpenh = resp.data.data.id;
          this.idxtpbheader = resp.data.data.auto_tpb;
          this.kodedokumenpabean = resp.data.data.jenis_bc;
          this.nomoraju = resp.data.data.nama_supplier;
          this.nomordaftar = resp.data.data.bc_no;
          this.tgldaftar = resp.data.data.bc_date;
          this.receiptno = resp.data.data.receipt_number;
          this.valuesup = resp.data.data.kode_supplier;
          this.notespenh = resp.data.data.notes;
          this.tglreceipt = resp.data.data.receipt_date;
          this.blnumber = resp.data.data.bl_number;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updatesup(value){
      this.valuesup = value;
    },
    getSupplierList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "supplier/getalldatasupplier?length=55000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.suplist = resp.data.data;
          this.suplist.forEach((item) => {
            item.value = item.kode_supplier;
            item.label = item.nama_supplier;
          });
          this.loading = false;
          this.loadData();
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          })
        });
    },
    async updateData() {
      this.loading = true;
      var idxpeneh = this.idxpenh;
      var norec = this.receiptno;
      var kdsup = this.valuesup;
      var catatan = this.notespenh;
      var tglrec = this.tglreceipt;
      var nobl = this.blnumber;
      if (norec == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Receipt Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kdsup == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatan == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglrec == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Receipt Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nobl == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "BL Number / Travel Document can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          receipt_number: this.receiptno,
          kode_supplier: this.valuesup,
          notes: this.notespenh,
          receipt_date: this.tglreceipt,
          kode_user: kodeuser,
          auto_tpb: this.idxtpbheader,
          bl_number: this.blnumber,
          bc_no: this.nomordaftar,
          bc_date: this.tgldaftar,
          jenis_bc: this.kodedokumenpabean
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPIUpdateData = "http://26.183.23.191/inventory/backend/inventory/api/penerimaan_h/updatepenerimaan_h";
        const urlAPIUpdateData =
          this.$apiurl + "penerimaan_h/updatepenerimaan_h/" + idxpeneh;
        axios
          .put(urlAPIUpdateData, paramdatadetail, { headers: headers })
          .then((respn) => {
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "penerimaanheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
